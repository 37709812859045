import React from 'react';
// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';
import 'swiper/css';
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import './Carousel.scss';

// install Swiper modules
SwiperCore.use([Pagination]);

const Carousel = ({title, transparent, children}) => {

  const carouselClass = transparent ? "Carousel no-background" : "Carousel"
  let slides;
  if (Array.isArray(children)) {
    slides = children.map((slide, index) => {
      return <SwiperSlide key={index}>{slide}</SwiperSlide>
    })
  }

  return (
    <div className={carouselClass}>
      <div className='title header'>
        <h2>{title}</h2>
      </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={'auto'}
        centeredSlides={true}
        pagination={{
          "clickable": true}}
        className="mySwiper"
      >
        {slides}
      </Swiper>
    </div>
  )
}

export default Carousel