import React from 'react'
import './FactBox.scss'
import MDXSplitter from './MDXSplitter'

const FactBox = ({children}) => {
  return (
    <div className='FactBox'>
      <img className="lightbulb" src="/assets/lightbulb.png" alt="Lightbulb" />
      <h5 className="title"> FAST FACT</h5>
      <div className='fact-body'>
        <MDXSplitter>{children}</MDXSplitter>
      </div>

    </div>
  )
}

export default FactBox