import { Link } from 'gatsby'
import React from 'react'
import './Slide.scss'

const Slide = ({title, image, destination}) => {
  return (
    <div className='Slide'>
      <Link to={destination}>
        <img src={`${image}`} alt={title}/>
        <div className="text">
          <h2>{title}</h2>
        </div>
      </Link>
    </div>
  )
}

export default Slide