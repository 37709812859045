import React from 'react'
import Markdown from 'markdown-to-jsx';


const splitChildren = (children) => {
  if (Array.isArray(children)) {
    // Children array will be comprised of JSX components and blocks of Markdown
    return children.map((child, idx) => {
      if (typeof child === 'string') {
        return <Markdown key={idx}>{child}</Markdown>
      } else {
        return <div key={idx}>{child}</div>
      }
    }) 
  } else if (typeof children === 'string') {
    return <Markdown>{children}</Markdown>
  } else {
    return children
  }
}

const MDXSplitter = ({children}) => {
  const mdxParts = splitChildren(children)
  return (
    <div className='MDXSplitter'>
      {mdxParts}
    </div>
  )

}

export default MDXSplitter